<template>
   <main id="main" class="site-main">
        <PricingPlan1List/>
    </main>
</template>
<script>
import PricingPlan1List from './PricingPlan1/PricingPlan1List'

export default {
  name: 'PricingPlan1',
  components: {
    PricingPlan1List
  }

}
</script>
